import React from "react";
import "./Scss/Contacts.scss";
import { useState } from "react";
import classNames from "classnames";
import p1 from "../../assets/images/p1.svg";
import p2 from "../../assets/images/p2.svg";
import p3 from "../../assets/images/p3.svg";
import p4 from "../../assets/images/p4.svg";
import p5 from "../../assets/images/p5.svg";
import p6 from "../../assets/images/p6.svg";
import p7 from "../../assets/images/p7.svg";
import p8 from "../../assets/images/p8.svg";
import p9 from "../../assets/images/p9.svg";
import p10 from "../../assets/images/p10.svg";
import p11 from "../../assets/images/p11.svg";
import p12 from "../../assets/images/p12.svg";
import p13 from "../../assets/images/p13.svg";
import p14 from "../../assets/images/p14.svg";
import p15 from "../../assets/images/p15.svg";
import p16 from "../../assets/images/p16.svg";
import p17 from "../../assets/images/p17.svg";
import p18 from "../../assets/images/p18.svg";
import p19 from "../../assets/images/p19.svg";
import p20 from "../../assets/images/p20.svg";
import p21 from "../../assets/images/p21.svg";
import p22 from "../../assets/images/p22.svg";
const Contacts = ({ changeModal, uzbek, russian }) => {
  const data = [
    {
      id: 1,
      name_uz: "Групп освещения (Дискретных)        ",
    },
    {
      id: 2,
      name_uz: "Групп освещения (Диммируемых)        ",
    },
    {
      id: 3,
      name_uz: "Лента W (одноцветная)        ",
    },
    {
      id: 4,
      name_uz: "Лента WW (двухцветная, белая и желтая лента)        ",
    },
    {
      id: 5,
      name_uz: "Лента RGBW (четырехцветная)   ",
    },
    {
      id: 6,
      name_uz: "Лента W (одноцветная)        ",
    },
    {
      id: 7,
      name_uz: "Лента RGBWW (пятицветная)   ",
    },
    {
      id: 8,
      name_uz: "Выключателей (из стандартной серии)    ",
    },
    {
      id: 9,
      name_uz: "Выключателей (беспроводных)  ",
    },
    {
      id: 10,
      name_uz: "Панелей подключаемых по шине      ",
    },
    {
      id: 11,
      name_uz: "Датчиков движения      ",
    },
    {
      id: 12,
      name_uz: "Датчиков освещенности     ",
    },
    {
      id: 13,
      name_uz: "Моторизованных штор ",
    },
    {
      id: 14,
      name_uz: "Рулонных штор  ",
    },
    {
      id: 15,
      name_uz: "Римских штор ",
    },
    {
      id: 16,
      name_uz: "Гаражных ворот   ",
    },
    {
      id: 17,
      name_uz: "Откатных ворот     ",
    },
    {
      id: 18,
      name_uz: "Управляемых розеточных групп ",
    },
    {
      id: 19,
      name_uz: "Зон теплого пола",
    },
    {
      id: 20,
      name_uz: "Радиаторных групп     ",
    },
    {
      id: 12,
      name_uz: "Комнатных терморегуляторов (с дисплеем/без дисплея)   ",
    },
    {
      id: 13,
      name_uz: "Система конидиционирования с обратной связью",
    },
    {
      id: 14,
      name_uz: "Система конидиционирования  без обратной связи по ИК	",
    },
    {
      id: 15,
      name_uz: "	Система вентиляции с обратной связью        ",
    },
    {
      id: 16,
      name_uz: "Вытяжных вентиляторов",
    },
    {
      id: 17,
      name_uz: "Количество датчиков протечки (проводных) накладных	  ",
    },
    {
      id: 18,
      name_uz: "Количество датчиков протечки (проводных) встраиваемых",
    },

    {
      id: 10,
      name_uz: "Количество датчиков протечки (беспроводных)	 ",
    },
    {
      id: 11,
      name_uz: "Количество кранов перекрывающих воду	 ",
    },
    {
      id: 12,
      name_uz: "Количество вводов воды   ",
    },
    {
      id: 13,
      name_uz: "Количество уличных камер ",
    },
    {
      id: 14,
      name_uz: "Количество внутренних камер  ",
    },
    {
      id: 15,
      name_uz: "Управление TV",
    },
    {
      id: 16,
      name_uz: "Мультирум зон (стерео)	   ",
    },
    {
      id: 17,
      name_uz: "Домашних кинотеатров (5.1, 7.1)	   ",
    },
    {
      id: 18,
      name_uz: "Точек WI-FI	",
    },
    {
      id: 19,
      name_uz: "Панелей управления",
    },
    {
      id: 20,
      name_uz: "Извещателей пожарных тепловых	        ",
    },
    {
      id: 12,
      name_uz: "Домофония	",
    },
    {
      id: 13,
      name_uz: "Внешних панели",
    },
    {
      id: 14,
      name_uz: "Внутренних панелей	",
    },
    {
      id: 15,
      name_uz: "ЗДЕСЬ ПРОПИСЫВАТЬ ПОЖЕЛАНИЯ.  ",
    },
  ];
    const newdata = [
    {
      img: p1,
      name: "Audio tizimlar",
      name_ru: "Аудиосистемы ",
    },
    {
      img: p2,
      name: "Shamollatish ",
      name_ru: "Вентиляторы ",
    },
    {
      img: p3,
      name: "Almashtirgich",
      name_ru: "Выключатели ",
    },
    {
      img: p4,
      name: "Datchiklar",
      name_ru: "Датчики ",
    },
    {
      img: p5,
      name: "Garaj eshiklari",
      name_ru: "Двери гаража ",
    },
    {
      img: p6,
      name: "Eshik qo'ng'iroqlari",
      name_ru: "Дверные звонки ",
    },
    {
      img: p7,
      name: "Qulflar",
      name_ru: "Замки ",
    },
    {
      img: p8,
      name: "Kameralar",
      name_ru: "Камеры ",
    },
    {
      img: p9,
      name: "Konditsionerlar  ",
      name_ru: "Кондиционеры ",
    },
    {
      img: p10,
      name: "Routerlar",
      name_ru: "Маршрутизаторы ",
    },
    {
      img: p11,
      name: "Oyna",
      name_ru: "Окна  ",
    },
    {
      img: p12,
      name: "Yoritish",
      name_ru: "Освещение  ",
    },
    {
      img: p13,
      name: "Xavfsizlik tizimlari",
      name_ru: "Охранные Системы ",
    },
    {
      img: p14,
      name: "Havo tozalagichlar",
      name_ru: "Очистители воздуха  ",
    },
    {
      img: p15,
      name: "Qabul qiluvchilar",
      name_ru: "Ресиверы  ",
    },
    {
      img: p16,
      name: "Rozetkalar",
      name_ru: "Розетки  ",
    },
    {
      img: p17,
      name: "Tarmoq yo'llari",
      name_ru: "Сетевые мосты  ",
    },
    {
      img: p18,
      name: "Sug'orish tizimlari",
      name_ru: "Системы Полива  ",
    },
    {
      img: p19,
      name: "Mikserlar",
      name_ru: "Смесители  ",
    },
    {
      img: p20,
      name: "Televizorlar",
      name_ru: "Телевизоры  ",
    },
    {
      img: p21,
      name: "Termostatlar",
      name_ru: "Термостаты  ",
    },
    {
      img: p22,
      name: "Namlagichlar",
      name_ru: "Увлажнители воздуха  ",
    },
  ];
  const [nameValue, setNameValue] = useState("");
  const [numberValue, setNumberValue] = useState("");
  const [invalidName, setInvalidName] = useState(false);
  const [invalidNumber, setInvalidNumber] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const handleChange = (event) => {
    // console.log(event?.target?.alt === undefined ? event?.target?.__reactProps$m19lzldszxj?.value : event?.target?.alt,"event")
    // console.log( event?.target?.alt,"eventateargetalt")
    setCheckedItems((prevCheckedItems) =>
    
    // console.log(prevCheckedItems,"prevCheckedItemsprevCheckedItems")
      event.target.alt
        ? [...prevCheckedItems, event.target.alt]
        : prevCheckedItems.filter((item) => item !== event.target.alt)
    );
  };
  const handleChange1 = (event) => {
    // console.log(event?.target?.alt === undefined ? event?.target?.__reactProps$m19lzldszxj?.value : event?.target?.alt,"event")
    // console.log( event?.target?.outerText ,"evenoutertest")
    setCheckedItems((prevCheckedItems) =>
      event.target.outerText
        ? [...prevCheckedItems, event.target.outerText]
        : prevCheckedItems.filter((item) => item !== event.target.outerText)
    );
  };
  function changeName(e) {
    setNameValue(e.target.value);
    setInvalidName(false);
  }

  function changeNumber(e) {
    setNumberValue(e.target.value);
    setInvalidNumber(false);
  }
console.log(checkedItems,"checkedItemssss")
  let bot = {
    TOKEN: "6107837450:AAHlITUrFXUn-LIJrMkuFbStMeulP3T9MII",
    chatID: "-1001538631991",
    message: `
        Salom, Yangi Xabar!😊%0A
        Ismi 👤: ${nameValue}%0A
        Raqam ☎: ${numberValue}%0A        Xizmatlar ✍🏻: ${checkedItems}%0A`,
  };
  function sendMessage() {
    if (nameValue === "") {
      setInvalidName(true);
    } else if (numberValue === "") {
      setInvalidNumber(true);
    } else {
      fetch(
        `https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.chatID}&text=${bot.message} `,
        {
          method: "GET",
        }
      ).then(
        (success) => {
          console.log("send message");
        },
        (error) => {
          console.log(error);
        }
      );
      changeModal();
      setNameValue("");
      setNumberValue("");
    }
  }


  return (
    <div className="Contacts" id="contacts">
      <div className="wrapper">
        <div className="col-10 texts">
          <p className="desc">
            {uzbek &&
              "Bizning operatorlarimiz sizga tez orada javob berishadi."}
            {russian && "Наши операторы ответят вам в ближайшее время."}
          </p>
          <h1 className="title">
            {uzbek && "O'z telefon raqamingizni qoldiring"}
            {russian && "Оставьте свой номер телефона"}
          </h1>
        </div>
        <div className="col-12 form">
          <form className="body" onSubmit={() => sendMessage()}>
            <input
              type="text"
              value={nameValue}
              className={`form-control ${invalidName && "red-line"}`}
              onChange={(e) => changeName(e)}
              placeholder="Ismi"
            />
            <input
              type="number"
              value={numberValue}
              className={`form-control number ${invalidNumber && "red-line"}`}
              onChange={(e) => changeNumber(e)}
              placeholder="Telefon raqami"
            />
            <div className="label-box">
              {newdata.map((e, i) => (
                <div
                  key={i}
                  onClick={handleChange1}
                  value={e?.name_ru}
                  style={{ cursor: "pointer" }}
                  className="contact-divs"
                >

                  <img
                    onClick={handleChange}
                    className="contact-icons"
                    src={e?.img}
                    alt={e?.name_ru}
                  />
                  <p className="contact-flexes" onClick={handleChange1}>
                    {e.name_ru}
                  </p>
                </div>
              ))}
            </div>
            <button className="send">
              {uzbek && "Yuborish"}
              {russian && "Отправка"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contacts;