import './Scss/Navbar.scss';
import React, { useState } from 'react';
 import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/logoj.png'; 
import { Close, Menu } from '@mui/icons-material';

const Navbar = ({ changeUzbek, changeEnglish, changeRussian, langTitle, uzbek, russian }) => {

    window.addEventListener("scroll", () => {
        const navbar = document.getElementById("navbar");
        navbar.classList.toggle("black", window.scrollY > 10);
    });

    const [open, setOpen] = useState(false);

    // languages

    const [language, setLanguage] = useState(false);

    function changeHandleUzbek() {
        changeUzbek(true)
        setLanguage(!language)        
    };

    // function changeHandleEnglish() {
    //     changeEnglish(true)
    //     setLanguage(!language)        
    // }
    function changeHandleRussian() {
        changeRussian(true)
        setLanguage(!language)        
    }
    
    const {t} = useTranslation();

    return (
        <div className='Navbar' id='navbar'>
            <nav className='navbar navbar-expand'>
                <a href='/#' className='navbar-brand logo'>
                    <img src={Logo} alt="logo" style={{width: "80px",height: "80px"}} />
                </a>
                <ul className={`navbar-nav ${open && "open"}`}>
                    <li className="nav-item">
                        <a href='/#ScrollY' className="nav-link" onClick={() => setOpen(false)}>{t("nav1")}</a>
                    </li>
                    <li className="nav-item">
                        <a href='/#about' className="nav-link"onClick={() => setOpen(false)}>{t("nav2")}</a>
                    </li>
            
                  
                    <li className="nav-item">
                        <a href="/#contacts" className="nav-link"onClick={() => setOpen(false)}>{t("nav5")}</a>
                    </li>
                    <li className="nav-item nav-tel">
                        <a href='tel:+998977772929' className="nav-link">+998 97 777 29 29</a>
                    </li>
                    <li className="nav-item">
                        <div className="language">
                            <input type="checkbox" id="language" checked={language} onChange={() => setLanguage(!language)} />
                            <label htmlFor='language'>{langTitle} <i className="fa fa-angle-down"></i></label>                                
                            <ul className={`language-menu ${!language && "d-none"}`}>
                                <li onClick={() => changeHandleUzbek()}><div className='lang-link'>O'Z</div></li>                
                                {/* <li onClick={() => changeHandleEnglish()}><div className='lang-link'>EN</div></li> */}
                                <li onClick={() => changeHandleRussian()}><div className='lang-link'>РУ</div></li>
                                <div className="contrast-0" onClick={() => setLanguage(!language)}></div>
                            </ul>
                        </div>   
                    </li>
                    <Close className='close-icon' onClick={() => setOpen(false)} />
                </ul>
                <a href='tel:+998977772929' className='navbar-brand tel-number'>
                    <div className="tel">+998 97 777 29 29</div>
                    <span className="aloqa">{t("nav6")}</span>
                </a>
                <Menu className='open-icon' onClick={() => setOpen(true)} />
            </nav>
        </div>
    );
};

export default Navbar;