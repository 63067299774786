import './Scss/Services.scss';
import { Link } from 'react-router-dom';
import Logo1 from '../../assets/images/logo1.png';
import React, { useEffect, useState } from 'react';
import r1 from "../../assets/images/t7.jpg"
import r2 from "../../assets/images/newkalit.jpeg"
import r3 from "../../assets/images/newr.jpg"
import r4 from "../../assets/images/w2.jpg"
import s9 from "../../assets/images/w9.jpg";

const Services = ({  russian, uzbek }) => {

    const [, setScroll] = useState(0);
    const [, setRotate] = useState(0);
    const [, setMarginTop] = useState(0);

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                window.addEventListener("scroll", () => {                    
                    if(30 - (window.scrollY - entry.target.offsetTop)/6 > 0 && window.innerWidth > 600){
                        setScroll(window.scrollY - entry.target.offsetTop)                                       
                    } 
                    if(30 - (window.scrollY - entry.target.offsetTop)/6 <= 0 && window.innerWidth > 600){
                        setScroll(180)                                       
                    } 
                    
                    if(window.scrollY - entry.target.offsetTop > 200 && 210 - (window.scrollY - entry.target.offsetTop)/6 > 0 && window.innerWidth > 600){
                        setRotate(window.scrollY - entry.target.offsetTop - 200)                                       
                    }
                    if(window.scrollY - entry.target.offsetTop > 200 && 210 - (window.scrollY - entry.target.offsetTop)/6 <= 0 && window.innerWidth > 600){
                        setRotate(180*6)                                       
                    }
                   
                    if(210 - (window.scrollY - entry.target.offsetTop)/6 < 0){                                                               
                        setMarginTop(210 - (window.scrollY - entry.target.offsetTop)/6)
                    }
                    if(210 - (window.scrollY - entry.target.offsetTop)/6 >= 0){                                                           
                        setMarginTop(0)
                    }                     
                })
            })
        },        
    );

    useEffect(() => {        
        const parent = document.getElementById("ScrollY")        
        observer.observe(parent)
    }, []);

    const dataServices = [
 
        {
            id:1,
            photo: r1,
            name_uz: `HomeKit Aqlli Pardalari`,
            name_ru: "Умные шторы HomeKit",
            tag_uz: "HomeKit aqlli pardalari bilan hayot yanada zavqli ko'rinishga kiradi. Birgina qo'lingizdagi telefon orqali istalgan paytda pardalarni boshqaring.",
            tag_ru: "Жизнь становится приятнее с умными шторами HomeKit. Управляйте жалюзи в любое время с телефона в руке."   },
        {
            id:2,
            photo: r2,
            name_uz: "HomeKit Aqlli Qulflari",
            name_ru: "Умные замки HomeKit",
            tag_uz: "Smart qulflar orqali kirish kodi bilan eshikni qulflang va qulfdan chiqaring. Do'stlar, oila va mehmonlar uchun kalitsiz kirish orqali kirish  va hisob ma'lumotlarini osongina yarating va yuboring.",
            tag_ru: "Блокируйте и открывайте дверь с помощью кода доступа через умные замки. Легко создавайте и отправляйте логины и учетные данные друзьям, родственникам и гостям с помощью Keyless Entry.",
},
        {
            id:3,
            photo: r3,
            name_uz: "Smart Rozetkalar",
            name_ru: "Умная розетка",
            tag_uz: "Maishiy texnika, vaqt kalitlari va o'lchash asboblarining quvvat sarfini masofadan boshqarish va jihozlarning ishdan chiqishi natijasida yuzaga keladigan baxtsiz hodisalarni HomeKit bilan oldini oling.",
            tag_ru: "Удаленно контролируйте энергопотребление приборов, таймеров и счетчиков и избегайте несчастных случаев, вызванных сбоем оборудования, с помощью HomeKit.",
} , {
            id:4,
            photo: r4,
            name_uz: "HomeKit bilan Uyni aqlli boshqarish",
            name_ru: "Управление умным домом с помощью HomeKit",
            tag_uz: "Hisoblagichlar, rozetkalar kabi aqlli qurilmalar orqali kvartirani energiyani tejashni boshqaring va elektr energiyasini isrof qilishning oldini olish uchun bizning masofaviy va muntazam elektr jihozlarimizdan foydalaning",
            tag_ru: "Управляйте энергосбережением в своей квартире с помощью интеллектуальных устройств, таких как счетчики, розетки, и используйте наши удаленные и обычные электроприборы, чтобы не тратить электроэнергию впустую.",
},
        {
            id:5,
            photo: s9,
            name_uz: "HomeKit  Aqlli Kameralari",
            name_ru: "Интеллектуальные камеры HomeKit",
            tag_uz: "Aqlli kameralar obyektivni, kamerani, boshqaruvni, protsessorni va modelga qarab, ixcham tasvirni qayta ishlash yoki dasturlash tilini bilmasangiz ham, ko'rish sensorini sozlash oson! Dastlabki foydalanuvchi interfeyslari har qanday ko'rish tizimiga tezkor integratsiyalashuvdan tashqari, real vaqt rejimida tizim bilan o'zaro ta'sir o'tkazish imkonini beradi.",
            tag_ru: "У умных камер есть объектив, камера, управление, процессор, и в зависимости от модели легко настроить датчик обзора, даже если вы не знаете компактную обработку изображений или язык программирования! Ранние пользовательские интерфейсы позволяют взаимодействовать с системой в режиме реального времени, а также быстро интегрируются в любую систему машинного зрения.",
}
    ]
    return (
        <div className='Services' id='allservices'>
            <div className="wrapper">        
            {/* style={{marginTop: window.innerWidth > 767 ? `${marginTop/2}%` : 0,}} */}
                <h1 className="title" >{uzbek && "Bizning xizmatlarimiz"} {russian && "Наши услуги"}</h1>
                <p className="title-text" >{uzbek && "HomeKit kompaniyamiz 2019-yilda tashkil topgan bo'lib ilk bor O'zbekistonda «HomeKit» nomi bilan ish boshlagan. Xizmatlarimiz turlari quyidagilardan iborat:"}
                 {russian && "Наша компания HomeKit была основана в 2019 году и впервые начала работать в Узбекистане под названием «HomeKit». Виды наших услуг следующие:"} </p>
                <div className="services-card">
                {/* style={{marginLeft: window.innerWidth > 767 ? `${scroll != 0 ? 30 - scroll/6 : 100}%` : 0}} */}
                    {dataServices.slice(0,1).map((data) => (
                        <div key={data.id} className="col-12 service">                                                
                        {/* style={{transform: `rotateY(${window.innerWidth > 767 ? 180-rotate/6 : 0}deg)`}} */}
                            <Link to={`#`} className="body">
                                <div className="col-6 img">
                                    <img className='services-images' src={data.photo} alt="service" />
                                </div>
                                <div className="texts col-6">
                                    <h1 className="name"> {russian && data.name_ru} {uzbek && data.name_uz}</h1>
                                    <p className="desc">{russian && data.tag_ru.slice(0,150)}{uzbek && data.tag_uz.slice(0,150)}</p>
                                </div>
                            </Link>
                            <Link to={`#`} className="body back">
                                <img src={Logo1} alt="logo" />
                            </Link>
                        </div>
                    ))}
                    {dataServices.slice(1,3).map((data) => (
                        <div key={data.id} className="col-6 service">                            
                         {/* style={{transform: `rotateY(${window.innerWidth > 767 ? 180-rotate/6 : 0}deg)`}} */}
                            <Link to={`#`} className="body">
                                <div className="col-12 img small-img">
                                    <img className='services-images' src={data.photo} alt="service" />
                                </div>
                                <div className="texts col-12">
                                    <h1 className="name"> {russian && data.name_ru} {uzbek && data.name_uz}</h1>
                                    <p className="desc"> {russian && data.tag_ru.slice(0,210)}{uzbek && data.tag_uz.slice(0,210)}</p>
                                </div>
                            </Link>
                            <Link to={`#`} className="body back">
                                <img src={Logo1} alt="logo" />
                            </Link>
                        </div>
                    ))}
                    {dataServices.slice(3,4).map((data) => (
                        <div key={data.id} className="col-12 service">                            
                         {/* style={{transform: `rotateY(${window.innerWidth > 767 ? 180-rotate/6 : 0}deg)`}} */}
                            <Link to={`#`} className="body">
                                <div className="col-6 img">
                                    <img className='services-images' src={data.photo} alt="service" />
                                </div>
                                <div className="texts col-6">
                                    <h1 className="name"> {russian && data.name_ru} {uzbek && data.name_uz}</h1>
                                    <p className="desc">{russian && data.tag_ru.slice(0,300)}{uzbek && data.tag_uz.slice(0,300)}</p>
                                </div>
                            </Link>
                            <Link to={`#`} className="body back">
                                <img src={Logo1} alt="logo" />
                            </Link>
                        </div>
                    ))}
                                        {dataServices.slice(4,5).map((data) => (
                        <div key={data.id} className="col-12 service">                            
                         {/* style={{transform: `rotateY(${window.innerWidth > 767 ? 180-rotate/6 : 0}deg)`}} */}
                            <Link to={`#`} className="body">
                                <div className="col-6 img">
                                    <img className='services-images' src={data.photo} alt="service" />
                                </div>
                                <div className="texts col-6">
                                    <h1 className="name"> {russian && data.name_ru} {uzbek && data.name_uz}</h1>
                                    <p className="desc">{russian && data.tag_ru.slice(0,380)}{uzbek && data.tag_uz.slice(0,380)}</p>
                                </div>
                            </Link>
                            <Link to={`#`} className="body back">
                                <img src={Logo1} alt="logo" />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;