import './Scss/AboutHome.scss';
import { LocationOn } from '@material-ui/icons';
import Img from '../../assets/images/logoj.png';
import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';

const AboutHome = ({uzbek, russian}) => {

    // scroll lenta

    const [, setScroll] = useState(0)

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                window.addEventListener("scroll", () => {
                    if(window.scrollY - entry.target.offsetTop < 2 * window.innerWidth){
                        setScroll(window.scrollY - entry.target.offsetTop + window.innerHeight*2/3)                                       
                    }
                })
            })
        },        
    )

    useEffect(() => {        
        const parent = document.getElementById("about")        
        observer.observe(parent)
    }, []);


    return (
        <div className='AboutHome' id='about'>
            <div className="wrapper">
                <div className="col-12 text-card">
                    <Fade left >
                        <div className='body'>
                        <h1 className="title">{uzbek && "Biz haqimizda"} {russian && "O нас"}</h1>
                        <p className="desc">{uzbek && "2019-yilda Toshkent shahrida o’z faoliyatini boshladi.  “HomeKit” nomi ostida faoliyat boshlagan jamoamiz o’z oldiga O’zbekistonlik insonlarni aqlli uy bilan bo’ladigan  ko’prik vazifasini bajarish, ular uchun har doim eng yaxshi tanlovlarni taqdim qilishni maqsad qilib qo’ygan edi. So’nggi 3 yildan oshiq vaqt davomida jami 50 dan ortiq mijozlarimizni   rozi qildik va  loyihalarni muvaffaqqiyatli amalga oshirdik"} {russian && "В 2019 году начал свою работу в Ташкенте. Наша команда, начавшая работать под названием «HomeKit», поставила перед собой цель служить жителям Узбекистана мостом с умным домом, всегда предоставляя им лучшие варианты. За последние 3 года мы удовлетворили более 50 клиентов и успешно реализовали проекты."}</p>
                        {/* <Link to="/about" className='explore' >{t("explore")}</Link> */}
                        </div></Fade>
                    <Fade right className="col-5 person-card">
                        <div className="col-5 person-card">
                        <div className="person">
                            <img src={Img} alt="person" style={{width:"70px", height: "70px"}}/>
                            {/* <AccountCircle className='person-icon' /> */}
                            <h3 className="name">homekit.uz <br /><p>{uzbek && "O'zbekiston"} {russian && "Узбекистан"}</p></h3>
                        </div>
                        <p className="text">{uzbek && "Biz mahsulotlarimizni Xitoy, Buyuk Britaniya, Amerika, Kanada va Yevropadan olib kelamiz. Bizning hamkorlarimiz Toshkent City, Mirobod, Akay City va boshqalar. "} {russian && "Мы импортируем нашу продукцию из Китая, Великобритании, Америки, Канады и Европы. Нашими партнерами являются Ташкент Сити, Мирабад, Акай Сити и другие."}</p>
                        <div className="location"><LocationOn className='icon' />{uzbek && "Toshkent shahar va butun respublika bo'ylab"} {russian && "город Ташкент и вся республика"}</div>
                        <div className="youtube">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/tDetkOezsVk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div></div>
                    </Fade>
                </div>
            </div>
            {/* <div className="tape"><p style={{marginLeft: `-${scroll}px`}} >HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> *</div>
            <div className="crimson"><p>HomeKit</p> * <p>HomeKit</p></div>
            <div className="tape1"><p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p>HomeKit</p> * <p>HomeKit</p> <p>HomeKit</p> <p style={{marginLeft: `-${scroll}px`}} >HomeKit</p> *</div>
            <div className="grey"><p>HomeKit</p> * <p>HomeKit</p></div> */}
        </div>
    );
};

export default AboutHome;