import React from "react";
import "./Scss/Footer.scss";
import { useTranslation } from "react-i18next";
import Img1 from "../../assets/images/logoj.png";

const Footer = ({uzbek, russian}) => {
  const { t } = useTranslation();

  return (
    <div className="Footer">
      <div className="wrapper">
        <div className="footer-tools">
          <div className="col-3 tool">
            <a href="/#">
              <img src={Img1} alt="logo" />
            </a>
            {/* <p className="adress">{t("adres1")}</p> */}
            <div className="icons">
              <a href="tel:+998977772929">
                <i className="fa fa-phone"></i>
              </a>
              <a href="https://www.instagram.com/homekit.uz/">
                <i className="fa fa-instagram"></i>
              </a>
              <a href="https://t.me/Tilla717">
                <i className="fa fa-telegram"></i>
              </a>
            </div>
          </div>
          <div className="col-3 tool">
            <ul>

              <li className="title">{uzbek && "Kompaniya haqida"} {russian && "О компании"}</li>
              <li className="link">
                <a href="/#home" className="nav-link p-0">
                  {uzbek && "Bosh sahifa"} {russian && "Главная страница"}
                 </a>
              </li>
              <li className="link">
                <a href="/#about" className="nav-link p-0">
                  {uzbek && "Biz haqimizda"} {russian && "О нас"}
                 </a>
              </li>
              <li className="link">
                <a href="#contacts" className="nav-link p-0">
                  {uzbek && "Aloqa"}{russian && "Связь"}
                </a>
              </li>


            </ul>
          </div>
          <div className="col-3 tool">
            <ul>
              <li className="title">{uzbek && "Xizmatlarimiz"}{russian && "Наши услуги"}</li>
 
              <li className="link">
                <a href="/#advantage" className="nav-link p-0">
                  {uzbek && "Bizning afzalliklarimiz"}{russian && "Наши преимущества"}
                </a>
              </li>
              <li className="link">
                <a href="/#allservices" className="nav-link p-0">
                  {uzbek && "Bizning xizmatlarimiz"}{russian && "Наши услуги"}
                </a>
              </li>
              <li className="link">
                <a href="/#serviceabout" className="nav-link p-0">
                  {uzbek && "Xizmatlarimiz haqida"}{russian && "О наших услугах"}
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="col-3 tool">
            <iframe
              style={{ borderRadius: "8px" }}
              className="gmap_iframe"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=174&amp;height=132&amp;hl=en&amp;q=158 Mukimi Street, Tashkent, Uzbekistan&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div> */}
        </div>
        <div className="copywrite">
          <p className="reserve">{t("all_rights")}</p>
          <div className="developed">
            <p>{uzbek && "Barcha huquqlar himoyalangan"}{russian && "Bсе права защищены"}: </p>
            <a href="https://t.me/it_time_admin"className="copywrite-text" >
              IT-Time 
            {/* <img src={logo} alt="coding" style={{width: "50px", height:"50px", borderRadius:"50%"}} /> */}
            {/* <img src={Img4} alt="media" /> */}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
