import React from 'react';
 import './Scss/ProductTypes.scss';
 import x1 from "../../assets/images/x1.png"
import x2 from "../../assets/images/x2.png"
import x3 from "../../assets/images/x3.png"
import x4 from "../../assets/images/x4.png"
import x5 from "../../assets/images/x5.png"
import x6 from "../../assets/images/x6.png"

const ProductTypes = ({  russian, uzbek, dataAdvertising }) => {
const dataProductTypes = [
    {
        id: 1,
        name_uz: "Tajribali mutahasislar tomonidan mahsulotni uyingizga o'rnatib berish",
        name_ru: "Установка изделия у вас дома опытными специалистами",
        photo: x1
    },
    {
        id: 2,
        name_uz: "Mijozlar bilan uzoq muddatlik shartnomalar tuzish",
        name_ru: "Заключение долгосрочных договоров с клиентами",
        photo: x3
    },    {
        id: 3,
        name_uz: "Buyurtma qilingan mahsulotlarni O'zbekiston bo'ylab tezkor o'rnatish",
        name_ru: "Быстрая установка заказанной продукции по Узбекистану",
        photo: x2

    },    {
        id: 4,
        name_uz: "Mutahasislar tomonidan bepul konsultatsiya va maslahatlar",
        name_ru: "Бесплатная консультация и консультация от специалистов",
        photo: x4
    },    {
        id: 5,
        name_uz: "Rivojlangan davlatlardan sifatli mahsulotlar olib kelib o'rnatish",
        name_ru: "Импорт и установка качественной продукции из развитых стран",
        photo: x5
    },    {
        id: 6,
        name_uz: "O'zbek tadbirkorlari va o'zbek xonadonlari uchun sifatli xizmat taqdim etish        ",
        name_ru: "Предоставление качественных услуг узбекским предпринимателям и узбекским домохозяйствам",
        photo: x6
    }
]
   
 
    return (
        <div className={`ProductTypes`} id='ProductTypes'>
            <div className="wrapper">
                <h1 className="title" >{uzbek && "Xizmat turlari "} {russian && "Типы услуг"}</h1>
                <div className="types">
                    {dataProductTypes.map((data, i) => (
                        <div key={i} className="col-6">
                            <a href="#" className="body" >
                                <img src={data.photo} alt="type" />
                                <p className="type-name">  {russian && data.name_ru} {uzbek && data.name_uz}</p>
                            </a>
                        </div>
                    ))}
                </div>
                {/* <Slider {...settings} className='carousel'>
                    {dataAdvertising.map((data) => (
                        <div key={data.id} className="carousel-page">
                            <img src={data?.photo} alt="image" />
                            <div className="texts">
                                <p className="name">  {russian && data.name_ru} {uzbek && data.name_uz}</p>
                                <h1 className="desc"> {russian && data.description_ru} {uzbek && data.description_uz}</h1>
                                <div className="link">
                                    <a href={data.url} className='explore'>{t("explore")} <i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider> */}
            </div>
        </div>
    );
};

export default ProductTypes;