import "./Scss/Header.scss";
import Slider from "react-slick";
import React, { useState } from "react";
 import h2 from "../../assets/images/e11.jpg";
import h6 from "../../assets/images/t10.jpg";
import e3 from "../../assets/images/e3.jpg";
import e8 from "../../assets/images/e10.jpg";

const Header = ({ russian, uzbek, dataSliders }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const settings = {
    dots: true,
    speed: 1000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    pauseOnHover: false,
    autoplaySpeed: 20000,
    className: "slider variable-width",
    beforeChange: (current, next) => setSlideIndex(next),
  };

  const data = [
    {
      id: 1,
      photo: e8,
      name_uz: "HomeKit texnologiyalaridan foydalanish qulayligi:",
      name_ru: "Простота использования технологий HomeKit:",
      tag_uz:
        " Agar siz texnologiyani yaxshi bilmaydigan odam bo'lsangiz ham, qo'llanmalarni kuzatib, bu narsalar qanday ishlashini tushunishingiz mumkin. Aqlli uy texnologiyalari yordamida siz uyda bo'lmasangiz ham, 24/7 uy bilan bog'langaningizni his qilishingiz mumkin.",
      tag_ru:
        "Даже если вы не разбираетесь в технологиях, вы можете следовать руководствам и понять, как все это работает. Благодаря технологии «умный дом» вы можете чувствовать связь со своим домом 24 часа в сутки, 7 дней в неделю, даже когда вас нет дома.",
    },
    {
      id: 2,
      photo: h2,
      name_uz: "Eshiklarni qulflash va ochish HomeKit bilan osonroq.",
      name_ru: "С HomeKit запирать и открывать двери стало проще.",
      tag_uz:
        "Xavfsiz uylar har qanday uyning eng muhim jihati hisoblanadi. Uyingiz uchun qancha qulfdan foydalansangiz ham, uyingizga o'g'rilar va o'g'rilar kirishi xavfi doimo mavjud. HomeKit aqlli texnologiyalari bilan uyingizni aqlli himoya qiling.",
      tag_ru:
        "Безопасные дома являются наиболее важным аспектом любого дома. Независимо от того, сколько замков вы используете для своего дома, всегда существует риск того, что воры и взломщики проникнут в ваш дом. Умно защитите свой дом с помощью интеллектуальных технологий HomeKit.",
   
      },

    {
      id: 3,
      photo: h6,
      name_uz: "HomeKit bilan uyingizni boshqaring:",
      name_ru: "Управляйте своим домом с помощью HomeKit:",
      tag_uz:
        "Agar siz isitish tizimining haroratini oshirmoqchi bo'lsangiz yoki konditsioneringizdagi haroratni pasaytirmoqchi bo'lsangiz yoki uyingizdan uzoqda suv isitgichni yoqmoqchi bo'lsangiz yoki yotoqda yotganingizda hamma narsani o'chirmoqchi bo'lsangiz, ularni HomeKit orqali mobil telefoningizga bir marta bosish bilan bajaring.",
      tag_ru:
        "Если вы хотите увеличить температуру вашей системы отопления, или понизить температуру вашего кондиционера, или включить водонагреватель вдали от вашего дома, или выключить все, когда вы в постели, сделайте это одним нажатием на кнопку ваше мобильное устройство через HomeKit.",
    },
    {
      id: 4,
      photo: e3,
      name_uz: "HomeKit bilan energiyani tejash:",
      name_ru: "Экономьте энергию с HomeKit:",
      tag_uz:
        "Aqlli uy mahsulotlari bilan siz energiya sarfini isrof qilmasligiga ishonch hosil qilishingiz mumkin. Mashinalar, qurilmalar va jihozlar aqlli uy mahsulotiga ega bo'lganda, ulardan foydalanishdan so'ng darhol boshqarilishi va o'chirilishi mumkin.",
      tag_ru:
        "С продуктами для умного дома вы можете быть уверены, что потребление энергии не тратится впустую. Когда машины, устройства и бытовая техника имеют продукт «умный дом», их можно контролировать и выключать сразу после использования.",
    },
  ];
  return (
    <div
      id="home"
      className={`Header ${data.length === 0 && "bg-white"}`}
      style={{ height: window.innerHeight }}
    >
      <div className="wrapper">
        <Slider {...settings} className="carousel">
          {data.map((data) => (
            <div key={data.id} className="page">
              <img src={data.photo} alt="slider" className="image" />
              <div className="texts">
                <div className="text__blur">
                  <h1 className="name">
                    {russian && data.name_ru} {uzbek && data.name_uz}
                  </h1>
                  <p className="desc">
                    {russian && data.tag_ru} {uzbek && data.tag_uz}
                  </p>
                  <div className="link">
                    <a href="#contacts" className="explore">
                      Bog'lanish
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <ul className="numbers">
        {dataSliders.map((data) => (
          <li
            key={data.id}
            className={`${
              slideIndex === dataSliders.indexOf(data) && "dot-active"
            }`}
          >
            {dataSliders.indexOf(data) + 1}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Header;
