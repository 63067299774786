import "./Scss/SliderPage.scss";
import Slider from "react-slick";
import React, { useState } from "react";
import s1 from "../../assets/images/c33.png";
import s2 from "../../assets/images/11q.png";
 import s4 from "../../assets/images/s44.png";
import s5 from "../../assets/images/s55.png";
import s6 from "../../assets/images/s666.png";
import s7 from "../../assets/images/w7.png";
import s8 from "../../assets/images/w8.png";

const SliderPage = ({  russian, uzbek, dataTypes }) => {
    const [slideIndex, setSlideIndex] = useState(0);

    const settings = {
        dots: true,
        speed: 1000,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        centerMode: true,
        slidesToScroll: 1,
        cssEase: "linear",
        pauseOnHover: false,
        autoplaySpeed: 4000,
        centerPadding: "500px",
        beforeChange: (current, next) => setSlideIndex(next),
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    centerPadding: "400px",
                },
            },
            {
                breakpoint: 1350,
                settings: {
                    centerPadding: "350px",
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: "300px",
                },
            },
            {
                breakpoint: 1050,
                settings: {
                    centerPadding: "250px",
                },
            },
            {
                breakpoint: 800,
                settings: {
                    centerPadding: "200px",
                },
            },
            {
                breakpoint: 700,
                settings: {
                    centerPadding: "150px",
                },
            },
            {
                breakpoint: 500,
                settings: {
                    centerPadding: "100px",
                },
            },
            {
                breakpoint: 390,
                settings: {
                    centerPadding: "80px",
                },
            },
        ],
    };
    const data = [
        {
            id: 1,
            photo: s1,
            name_uz: "Yoritish, rozetkalar, pardalar",
            name_ru: "Освещение, розетки, шторы",
            description_uz:
                "Yorug'lik guruhlari (diskret), WW tasmasi (ikki rangli, oq va sariq lenta), RGB lenta (uch rangli), RGBW tasmasi (to'rt rangli), RGBWW tasmasi (besh rangli), kalitlar (standart seriyadan) , Kalitlar (simsiz), Avtobus panellari, harakat datchiklari, yorug'lik datchiklari, motorli soyalar, rolikli pardalar, rim pardalari, garaj eshiklari, toymasin eshiklar, boshqariladigan chiqish guruhlari",
            description_ru:
                "Групп освещения (Дискретных), Лента WW (двухцветная, белая и желтая лента), Лента RGB (трехцветная), Лента RGBW (четырехцветная), Лента RGBWW (пятицветная), Выключателей (из стандартной серии), Выключателей (беспроводных), Панелей подключаемых по шине, Датчиков движения, Датчиков освещенности, Моторизованных штор, Рулонных штор, Римских штор, Гаражных ворот, Откатных ворот, Управляемых розеточных групп ",
        },
        {
            id: 2,
            photo: s2,
            name_uz: "Mikroiqlim",
            name_ru: "Микроклимат",
            description_uz:
                "Yerdan isitish zonalari, Radiatorlar guruhlari, Xona termostatlari (displeyli/displeysiz), Teskari aloqa bilan konditsioner tizimi, IQ aloqasi bo'lmagan konditsioner tizimi, Teskari aloqa bilan ventilyatsiya tizimi, Egzoz fanatlar",
            description_ru:
                "Зон теплого пола, Радиаторных групп, Комнатных терморегуляторов (с дисплеем/без дисплея), Система конидиционирования с обратной связью, Система конидиционирования  без обратной связи по ИК, Система вентиляции с обратной связью, Вытяжных вентиляторов        ",
        },
        {
            id: 3,
            photo: s8,
            name_uz: "Oqish himoyasi",
            name_ru: "Защита от протечек",
            description_uz:
                "Oqish datchiklari soni (simli), o'rnatilgan oqish datchiklari (simli) soni, Oqish datchiklari soni (simsiz), suvni to'sib qo'yadigan kranlar soni, suv kirishlari soni",
            description_ru:
                "Количество датчиков протечки (проводных) накладных, Количество датчиков протечки (проводных) встраиваемых, Количество датчиков протечки (беспроводных), Количество кранов перекрывающих воду, Количество вводов воды",
        },
        {
            id: 4,
            photo: s4,
            name_uz: "Video monitoring",
            name_ru: "Видеонаблюдение",
            description_uz: "Tashqi kameralar soni, ichki kameralar soni",
            description_ru:
                "Количество уличных камер, Количество внутренних камер        ",
        },
        {
            id: 5,
            photo: s5,
            name_uz: "Multimedia",
            name_ru: "Мультимедиа",
            description_uz:
                "Televizorni boshqarish, Ko'p xonali zonalar (stereo), Uy kinoteatrlari (5.1, 7.1), WI-FI nuqtalari, Boshqaruv panellari",
            description_ru:
                "Управление TV, Мультирум зон (стерео), Домашних кинотеатров (5.1, 7.1), Точек WI-FI, Панелей управления ",
        },
        {
            id: 6,
            photo: s6,
            name_uz: "Xavfsizlik tizimi",
            name_ru: "Система безопасности",
            description_uz: "Termal yong'in detektorlari, Tutun detektorlari",
            description_ru:
                "Извещателей пожарных тепловых, Извещателей пожарных дымовых	",
        },
        {
            id: 7,
            photo: s7,
            name_uz: "Interkom",
            name_ru: "Домофония",
            description_uz: "Tashqi panellar, ichki panellar",
            description_ru: "Внешних панели, Внутренних панелей",
        },
    ];
    return (
        <div className="SliderPage" id="serviceabout">
            <Slider {...settings} className="carousel">
                {data.map((data, index) => (
                    <div
                        className={slideIndex === index ? "slide slide-active" : "slide"}
                        key={data.id}
                    >
                        <img className="sliderpage-img" src={data.photo} alt="plane" />
                        <h1 className="name">
                            {uzbek && data.name_uz} {russian && data.name_ru}
                        </h1>
                        <p className="desc">
                            
                            {russian && data.description_ru} {uzbek && data.description_uz}
                        </p>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SliderPage;
