import './index.scss';
import './i18next.js';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Suspense fallback={<SuspenseLoader />}> */}
      <App />
    {/* </Suspense> */}
  </React.StrictMode>
);
