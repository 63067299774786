import './App.scss';
import axios from 'axios';
import Add from './components/OurTeam/Add';
import Modal from './components/Modal/Modal';
 import { useTranslation } from 'react-i18next';
import Header from './components/Header/Header';
import Navbar from './components/Navbar/Navbar';
import Loader from './components/Loader/Loader';
import Footer from './components/Footer/Footer';
import React, { useEffect, useState } from 'react';
 import ScrollY from './components/Services/ScrollY';
import AboutHome from './components/About/AboutHome';
import NotFound from './components/NotFound/NotFound';
import Contacts from './components/Contacts/Contacts';
import Services from './components/Services/Services';
 import Advantage from './components/Advantage/Advantage';
 import SliderPage from './components/SliderPage/SliderPage';
import SearchProd from './components/SearchProd/SearchProd';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import TextAnimation from './components/Advantage/TextAnimation';
import ProductTypes from './components/ProductTypes/ProductTypes';
 
// AOS.init();

const App = () => {

 
  // urls of API

  const baseUrl = `https://api.chinwakil.com/api`;
  const urlSliders = `${baseUrl}/sliders`;
  // Sliders API

  const [dataSliders, setDataSliders] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlSliders);
      setDataSliders(request.data);
      return request;
    };
    fetchData()
  }, [urlSliders]);

  // languages

  const { i18n } = useTranslation();
  const [uzbek, setUzbek] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') == "uz" ? true : false : false);

  const [, setEnglish] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') == "en" ? true : false : false);
  const [russian, setRussian] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') == "ru" ? true : false : false);
  const [langTitle, setLangTitle] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').toUpperCase() : "")

  function changeUzbek(item) {
    setUzbek(item)
    setEnglish(!item)
    setRussian(!item)
    i18n.changeLanguage("uz")
    setLangTitle(localStorage.getItem('i18nextLng').toUpperCase())
  }

  function changeRussian(item) {
    setUzbek(!item)
    setRussian(item)
    setEnglish(!item)
    i18n.changeLanguage("ru")
    setLangTitle(localStorage.getItem('i18nextLng').toUpperCase())
  }

  function changeEnglish(item) {
    setUzbek(!item)
    setEnglish(item)
    setRussian(!item)
    i18n.changeLanguage("en")
    setLangTitle(localStorage.getItem('i18nextLng').toUpperCase())
  }

  // modal

  const [showModal, setShowModal] = useState(false)

  function changeModal() {
    setShowModal(!showModal)
  }

  // scrolls

  const scrollToLocation = (item) => {
    const { hash } = window.location;
    if (hash !== '') {
      let retries = 0;
      const id = hash.replace('#', '');
      const scroll = () => {
        retries += 0;
        if (retries > 50) return;
        const element = document.getElementById(id);
        if (element) {
          setTimeout(() => element.scrollIntoView(), 0);
        } else {
          setTimeout(scroll, 100);
        }
      };
      if (!item) {
        scroll();
      }
    }
  }

  scrollToLocation();

  // loader

  const [loaderShow, setLoaderShow] = useState(false);

  useEffect(() => {
      setLoaderShow(true)
  }, [])



  return (
    <BrowserRouter>
      {loaderShow && 
        <Loader /> 
      }
      <ScrollToTop />
      <Navbar changeUzbek={changeUzbek} changeEnglish={changeEnglish} changeRussian={changeRussian} langTitle={langTitle} />
      <Switch>
        <Route exact path="/">
          <Header uzbek={uzbek} russian={russian} dataSliders={dataSliders}  />
          <ScrollY  uzbek={uzbek} russian={russian}  />
          <Services uzbek={uzbek} russian={russian}  />
          <AboutHome uzbek={uzbek} russian={russian} />
          <SliderPage uzbek={uzbek} russian={russian}  />
          {/* <LastNews uzbek={uzbek} russian={russian} dataNews={dataNews} other={false} id={null} /> */}
          <SearchProd uzbek={uzbek} russian={russian}   />
          <ProductTypes uzbek={uzbek} russian={russian}  />
          <Add   uzbek={uzbek} russian={russian} />
          {/* <OurTeam dataTeams={dataTeams} /> */}
          {/* <Tradition uzbek={uzbek} russian={russian} dataTraditions={dataTraditions} /> */}
          {/* <Partners dataPartners={dataPartners} /> */}
          <TextAnimation uzbek={uzbek} russian={russian} />
          <Advantage uzbek={uzbek} russian={russian} />
          <Contacts changeModal={changeModal} uzbek={uzbek} russian={russian}  />
          <Footer uzbek={uzbek} russian={russian} />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
      {showModal &&
        <Modal uzbek={uzbek} russian={russian} changeModal={changeModal} />
      }
    </BrowserRouter>
  );
};

export default App;



 
	

	

  
 




















