import React from 'react';

const Add = () => {
    return (
        <div id='Add'>
            
        </div>
    );
};

export default Add;