import React from 'react';
import './Scss/Modal.scss';

const Modal = ({ russian, uzbek, changeModal }) => {
    return (
        <>
            <div className='Modal'>
     
                {russian &&
                <h1>СПАСИБО 3A ОБРАЩЕНИЕ &#128522;</h1> 
                } 
                {uzbek &&
                <h1>MUROJAATINGIZ UCHUN TASHAKKUR &#128522;</h1> 
                }
                <button onClick={() => changeModal()}>OK</button>
            </div>
            <div className="contrast" onClick={() => changeModal()}></div>
        </>
    );
};

export default Modal;