import './Scss/SearchProd.scss';
import React, { useEffect, useState } from 'react';
import i1 from "../../assets/images/i1.png";
import i2 from "../../assets/images/i2.png";
 import i4 from "../../assets/images/i4.png";
import i5 from "../../assets/images/i5.png";
import i8 from "../../assets/images/i8.png";
 

const SearchProd = ({  russian, uzbek }) => {
     const dataSlider =[
        {
            id: 1,
            name_uz: "Video kuzatuv",
            name_ru:"Видеонаблюдение",
            description_uz:"HomeKit video kuzatuv texnologiyalari bilan uyingizdan havotir olishga chek qo'ying. Hatto, uzoq safarga ketsangiz ham uyingiz haqida bilib turishingiz mumkin.",
            description_ru: "Перестаньте беспокоиться о своем доме с технологиями видеонаблюдения HomeKit. Даже если вы отправляетесь в дальнюю поездку, вы можете узнать о своем доме.",
            photo: i5
        },
        {
            id: 2,
            name_uz: "PARDALAR",
            name_ru:"Шторы",
            description_uz:"Ishonchli va zamonaviy pardalar bilan o'zingiz uchun hashamatli hayotni kashf eting. Birgina boshqaruv pulti yoki telefoningiz bilan ham Homekit pardalarini boshqarish imkoniyati mavjud.",
            description_ru: "Откройте для себя роскошную жизнь с надежными и стильными шторами. Вы можете управлять жалюзи Homekit с помощью всего одного пульта дистанционного управления или телефона.",
            photo: i2
        },        {
            id: 3,
            name_uz: "Xavfsizlik tizimi",
            name_ru:"Система безопасности",
            description_uz:"Homekitning o'ziga hos xavfsizlik qurilmalari bilan siz qayerda bo'lishingizdan qat'iy nazar uyingiz doim siz bilan. Hatto, suv-oqava tizimini ham telefoningiz orqali boshqarishga nima deydiz? Aqlli qulflar bilan shaxsiy biometrik xavfsizlik tizimi yordamida o'z mulkingiz va oila a'zolaringizni  ishonchli muhofaza qiling.",
            description_ru: "Благодаря уникальным устройствам безопасности Homekit ваш дом всегда с вами, где бы вы ни находились. Как насчет того, чтобы даже управлять своей сантехнической системой с телефона? Надежно защитите свое имущество и членов семьи с помощью персональной биометрической системы безопасности с умными замками.",
            photo: i4
        },        {
            id: 4,
            name_uz: "Uy teatri",
            name_ru:"Домашний театр",
            description_uz:"Zamon bilan hamnafas bo'lgan holda Homekit bilan vaqtingiz va naqdingizni tejang. Chunki, endi teatrga tushishga hojat yo'q. Uning o'zini uyingizda quring va istalgan payt xona dizaynini o'zgartiring",
            description_ru: "Экономьте время и деньги с Homekit, идя в ногу со временем. Потому что теперь нет необходимости ходить в театр. Соберите его сами у себя дома и меняйте дизайн комнаты в любой момент",
            photo: i8
        },        {
            id: 5,
            name_uz: "Mikro iqlim",
            name_ru:"Микроклимат",
            description_uz:"Sovuq kunlarda o'zingizni Avstraliyadagidek his qilishingiz uchun Homekitning boshqarishga oson va qulayliklariga gap yo'q bo'lgan so'ngi avlod mikro iqlim qurilmalaridan foydalaning. Ushbu texnologiyalar xona uchun mo'tadil sharoitning o'zi o'zgartirib turishi bilan sizga haqiqiy yordamchi do'stga aylanadi.",
            description_ru: "Чтобы в холодные дни вы чувствовали себя как в Австралии, используйте устройства микроклимата нового поколения Homekit с простыми в использовании и удобными функциями. Эти технологии станут вашими настоящими помощниками, так как сами изменяют температуру в помещении.",
            photo: i1
        },    {  id: 6,
            name_uz: "Xavfsizlik tizimi",
            name_ru:"Система безопасности",
            description_uz:"Homekitning o'ziga hos xavfsizlik qurilmalari bilan siz qayerda bo'lishingizdan qat'iy nazar uyingiz doim siz bilan. Hatto, suv-oqava tizimini ham telefoningiz orqali boshqarishga nima deydiz? Aqlli qulflar bilan shaxsiy biometrik xavfsizlik tizimi yordamida o'z mulkingiz va oila a'zolaringizni  ishonchli muhofaza qiling.",
            description_ru: "Благодаря уникальным устройствам безопасности Homekit ваш дом всегда с вами, где бы вы ни находились. Как насчет того, чтобы даже управлять своей сантехнической системой с телефона? Надежно защитите свое имущество и членов семьи с помощью персональной биометрической системы безопасности с умными замками.",
            photo: i4
    },  
    ]

    const [scroll, setScroll] = useState(0);

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                window.addEventListener("scroll", () => {
                    if(window.scrollY - entry.target.offsetTop < 2 * window.innerWidth){
                        setScroll(window.scrollY - entry.target.offsetTop + window.innerHeight*3/2)                                       
                    }
                })
            })
        },        
    );

    useEffect(() => {        
        const parent = document.getElementById("SearchProd")        
        observer.observe(parent)
    }, []);

    //slider

    const [slideIndex, setSlideIndex] = useState(0);
    const [rotateSlide, setRotateSlide] = useState(1);
    const [transition, setTransition] = useState(false);

    function nextSlide(){
        setRotateSlide(rotateSlide - 1)
        if(slideIndex + 1 < dataSlider.length){
            setSlideIndex(slideIndex + 1)
        }else{
            setSlideIndex(0)
        }
        setTransition(true)
    };

    function prevSlide(){
        setRotateSlide(rotateSlide + 1)
        if(slideIndex > 0){
            setSlideIndex(slideIndex - 1)
        }else{
            setSlideIndex(dataSlider.length - 1)        // -1 qilganimni sababi slideIndex 0 dan boshlandi yani dataSliderning birinchi indexidan, uzunlik esa dataSliderning oxirgi indexidan bittaga ko'p
        }
        setTransition(true)
    };

    const [leftX, setLeftX] = useState(0);
    const [move, setMove] = useState(false);

    function moveHandle(item){
        setMove(item)
        if(item == false && leftX > 0){
            prevSlide()
            setLeftX(0)
        }else if(item == false && leftX < 0){
            nextSlide()
            setLeftX(0)
        }        
    };
    
    function onDragHandle(e){
        if(move === true){
            setLeftX(leftX + e.movementX)
            setTransition(true)
        }
    };

    function changeIndex(item){
        setSlideIndex(item)
        setRotateSlide(1 - item)
    };

    return (
        <div className='SearchProd' id='SearchProd'>            
            <div className="spinner" onMouseDown={() => moveHandle(true)} onMouseMove={(e) => move && onDragHandle(e)} onMouseUp={() => moveHandle(false)} style={{height: `${window.innerWidth > 400 ? window.innerWidth > 500 ? window.innerWidth > 600 ? window.innerWidth > 767 ? scroll*3/2 : scroll : scroll*5/6 : scroll*2/3 : scroll*3/5}px`, width: `${window.innerWidth > 400 ? window.innerWidth > 500 ? window.innerWidth > 600 ? window.innerWidth > 767 ? scroll*3/2 : scroll : scroll*5/6 : scroll*2/3 : scroll*3/5}px`, transform: `rotate(${360/dataSlider.length*rotateSlide + leftX/10}deg)`, transition: `${transition ? 0.5 : 0}s`}} >
                {dataSlider.map((data) => (                    
                    <div key={data.id} className={`body ${slideIndex == dataSlider.indexOf(data) && "active-body"}`} style={{transform: `rotate(${360/dataSlider.length*(dataSlider.indexOf(data) + 1)}deg) translateX(-3vw)`, transformOrigin: `${window.innerWidth > 400 ? window.innerWidth > 500 ? window.innerWidth > 600 ? window.innerWidth > 767 ? scroll*75/window.innerWidth : scroll*50/window.innerWidth : scroll*42/window.innerWidth : scroll*34/window.innerWidth : scroll*31/window.innerWidth}vw`, transition: `${transition ? 0.3 : 0}s`}}>
                        <img style={{transform: `rotate(${-360/dataSlider.length*(dataSlider.indexOf(data) + 1) - 360/dataSlider.length*rotateSlide}deg)`}} src={data.photo} alt="slider" />
                    </div>                    
                ))}
                {dataSlider.map((data) => (                                      
                    <div key={data.id} className={`texts ${dataSlider.indexOf(data) != slideIndex && "opacity-0"}`} style={{transform: `rotate(${0 - (360/dataSlider.length*rotateSlide + leftX/10)}deg)`}}>
                        <h1 className="name">  {russian && data.name_ru} {uzbek && data.name_uz}</h1>
                        <p className="desc"> {russian && data.description_ru} {uzbek && data.description_uz}</p>
                    </div>                  
                ))}                
                <div className="mirror" style={{transform: `rotate(${!move && 0 - (360/dataSlider.length*rotateSlide - leftX/10)}deg)`}}></div>
            </div>
            <ul className="spinner-dots">
                <button className='prev' onClick={() => prevSlide()}><i className="fa fa-angle-left"></i></button>
                {dataSlider.map((data) => (
                    <li key={data.id} className={`dot ${dataSlider.indexOf(data) == slideIndex && "dot-active"}`}>
                        <button onClick={() => changeIndex(dataSlider.indexOf(data))}></button>
                    </li>
                ))}
                <button className='next' onClick={() => nextSlide()}><i className="fa fa-angle-right"></i></button>
            </ul>
        </div>
    );
};

export default SearchProd;