import './Scss/Advantage.scss';
import Slider from 'react-slick';
import React, { useState, useEffect } from 'react';
import {  Sell, Verified,  ControlPoint } from '@mui/icons-material';

const Advantage = ({uzbek, russian}) => {

    const [scroll, setScroll] = useState(0);
    const [fixed, setFixed] = useState(false);

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                window.addEventListener("scroll", () => {
                    if (window.scrollY - entry.target.offsetTop > 0 && window.scrollY - entry.target.offsetTop < 2 * window.innerWidth) {
                        setScroll(window.scrollY - entry.target.offsetTop)
                        setFixed(true)
                    } else if (window.scrollY - entry.target.offsetTop <= 0 || window.scrollY - entry.target.offsetTop >= 2 * window.innerWidth) {
                        setFixed(false)
                    }
                })
            })
        }
    );

    useEffect(() => {
        const advantage = document.getElementById("advantage");
        observer.observe(advantage);
    }, []);

    const settings = {
        dots: true,
        speed: 1000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        pauseOnHover: false,
        autoplaySpeed: 4000,
        className: "slider variable-width"
    };

 
    return (
        <div className={`Advantage ${fixed ? "full-page" : ""}`} id='advantage'>
            <div id='scroll' className={`scroll scroll1`} style={{ top: 0 }}>
                <div className="horizontal col-12">
                    <div className="body">                                              
                        <ul className="texts">
                            <h1 className="title">{uzbek && "Afzalliklar"} {russian && "Преимущества"}</h1>
                            <li className="name"><Verified className='icon' /> {uzbek &&"Avtomatlashtirilgan mikroiqlim tizimi va har bir xona uchun yoritish stsenariylarini boshqarish bilan jihozlangan Aqlli uyingizni energiya xarajatlarini sezilarli darajada tejaydi."} {russian && "Оснащенный автоматизированной системой микроклимата и управлением сценариями освещения для каждой комнаты, ваш Умный дом значительно сэкономит затраты на электроэнергию."}</li>
                            <li className="name"><ControlPoint className='icon' />{uzbek && "Istalgan vaqtda smartfoningizda videokuzatuv kameralarini ko'rsatishingiz va uyingizning holatini tekshirishingiz mumkin."} {russian && "Вы можете показать камеры видеонаблюдения и проверить состояние вашего дома в любое время на вашем смартфоне."}</li>
                            <li className="name"><Sell className='icon' /> {uzbek && " Favqulodda vaziyatlar haqida masofaviy xabarnomalarni ham uy ichida, ham mahalliy joylarda avtomatlashtirilgan usulda aniqlay olasiz"}{russian && "Вы можете автоматически обнаруживать удаленные аварийные оповещения как в помещении, так и локально"}</li>
                        </ul>
                    </div>
                </div>
                <div className="horizontal col-12">
                    <div className="body">                                              
                    <ul className="texts">
                            <h1 className="title">{uzbek && "Afzalliklar"} {russian && "Преимущества"}</h1>
                            <li className="name"><Verified className='icon' /> {uzbek &&"Tizim sizni gaz portlashlari va yong'inlardan himoya qiladi"} {russian && "Система защитит вас от взрыва газа и возгорания"}</li>
                            <li className="name"><ControlPoint className='icon' />{uzbek && "Endi uy kinoteatringizni boshqarish oson va sodda - bir tugmani bosish bilan siz kerakli stsenariyni ishga tushirasiz ."} {russian && "Теперь управлять домашним кинотеатром легко и просто - нажатием кнопки вы запустите нужный сценарий."}</li>
                            <li className="name"><Sell className='icon' /> {uzbek && " Suv, gazning oqishi, konditsionerning noto'g'ri ishlashi - avtomatlashtirish tizimi sizni hamma narsa haqida ogohlantiradi"}{russian && "Вода, утечка газа, неисправность кондиционера - система автоматики обо всем вас предупредит"}</li>
                        </ul>
                    </div>
                </div>
                <div className="horizontal col-12">
                    <div className="body">                        
                    <ul className="texts">
                            <h1 className="title">{uzbek && "Afzalliklar"} {russian && "Преимущества"}</h1>
                            <li className="name"><Verified className='icon' /> {uzbek &&"Faqat bitta tugmani bosish bilan yorug'lik xiralashadi, pardalar yopiladi, mikroiqlim muvozanatlanadi. "} {russian && "Нажатием всего одной кнопки приглушается свет, закрываются шторы и балансируется микроклимат."}</li>
                            <li className="name"><ControlPoint className='icon' />{uzbek && "Yorqin xonalar, yorug'lik oqimlari, interyerni ijobiy ta'kidlaydi - do'stlaringiz uyingizda qulay va erkin his qiladilar."} {russian && "Светлые комнаты, потоки света, выгодно подчеркивающие интерьер - Ваши друзья будут чувствовать себя в Вашем доме комфортно и уютно."}</li>
                            <li className="name"><Sell className='icon' /> {uzbek && "Siz uxlayotganingizda, yorug'likni tekshirib, xonalarni kezishingiz shart emas. Choyshab yonidagi kalitni bir marta bosish bilan siz uydagi barcha chiroqlarni o'chirasiz va yoqimli uyquga ketasiz."}{russian && "Вам не нужно бродить по комнате, проверяя свет во время сна. Одним щелчком выключателя рядом с кроватью вы выключите весь свет в доме и отправитесь в приятный сон."}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id='scroll' className={`scroll`} style={{ transform: `translateX(-${scroll}px)` }}>
                <div className="horizontal col-12">
                    <div className="body">                                              
                    <ul className="texts">
                            <h1 className="title">{uzbek && "Afzalliklar"} {russian && "Преимущества"}</h1>
                            <li className="name"><Verified className='icon' /> {uzbek &&"Tizim sizni gaz portlashlari va yong'inlardan himoya qiladi"} {russian && "Система защитит вас от взрыва газа и возгорания"}</li>
                            <li className="name"><ControlPoint className='icon' />{uzbek && "Endi uy kinoteatringizni boshqarish oson va sodda - bir tugmani bosish bilan siz kerakli stsenariyni ishga tushirasiz ."} {russian && "Теперь управлять домашним кинотеатром легко и просто - нажатием кнопки вы запустите нужный сценарий."}</li>
                            <li className="name"><Sell className='icon' /> {uzbek && " Suv, gazning oqishi, konditsionerning noto'g'ri ishlashi - avtomatlashtirish tizimi sizni hamma narsa haqida ogohlantiradi"}{russian && "Вода, утечка газа, неисправность кондиционера - система автоматики обо всем вас предупредит"}</li>
                        </ul>
                    </div>
                </div>
                <div className="horizontal col-12">
                    <div className="body">                        
                    <ul className="texts">
                            <h1 className="title">{uzbek && "Afzalliklar"} {russian && "Преимущества"}</h1>
                            <li className="name"><Verified className='icon' /> {uzbek &&"Faqat bitta tugmani bosish bilan yorug'lik xiralashadi, pardalar yopiladi, mikroiqlim muvozanatlanadi. "} {russian && "Нажатием всего одной кнопки приглушается свет, закрываются шторы и балансируется микроклимат."}</li>
                            <li className="name"><ControlPoint className='icon' />{uzbek && "Yorqin xonalar, yorug'lik oqimlari, interyerni ijobiy ta'kidlaydi - do'stlaringiz uyingizda qulay va erkin his qiladilar."} {russian && "Светлые комнаты, потоки света, выгодно подчеркивающие интерьер - Ваши друзья будут чувствовать себя в Вашем доме комфортно и уютно."}</li>
                            <li className="name"><Sell className='icon' /> {uzbek && "Siz uxlayotganingizda, yorug'likni tekshirib, xonalarni kezishingiz shart emas. Choyshab yonidagi kalitni bir marta bosish bilan siz uydagi barcha chiroqlarni o'chirasiz va yoqimli uyquga ketasiz."}{russian && "Вам не нужно бродить по комнате, проверяя свет во время сна. Одним щелчком выключателя рядом с кроватью вы выключите весь свет в доме и отправитесь в приятный сон."}</li>
                        </ul>
                    </div>
                </div>
                <div className="horizontal col-12">
                    <div className="body">                                              
                        <ul className="texts">
                            <h1 className="title">{uzbek && "Afzalliklar"} {russian && "Преимущества"}</h1>
                            <li className="name"><Verified className='icon' /> {uzbek &&"Avtomatlashtirilgan mikroiqlim tizimi va har bir xona uchun yoritish stsenariylarini boshqarish bilan jihozlangan Aqlli uyingizni energiya xarajatlarini sezilarli darajada tejaydi."} {russian && "Оснащенный автоматизированной системой микроклимата и управлением сценариями освещения для каждой комнаты, ваш Умный дом значительно сэкономит затраты на электроэнергию."}</li>
                            <li className="name"><ControlPoint className='icon' />{uzbek && "Istalgan vaqtda smartfoningizda videokuzatuv kameralarini ko'rsatishingiz va uyingizning holatini tekshirishingiz mumkin."} {russian && "Вы можете показать камеры видеонаблюдения и проверить состояние вашего дома в любое время на вашем смартфоне."}</li>
                            <li className="name"><Sell className='icon' /> {uzbek && " Favqulodda vaziyatlar haqida masofaviy xabarnomalarni ham uy ichida, ham mahalliy joylarda avtomatlashtirilgan usulda aniqlay olasiz"}{russian && "Вы можете автоматически обнаруживать удаленные аварийные оповещения как в помещении, так и локально"}</li>
                        </ul>
                    </div>
                </div>
 
            </div>
            <Slider {...settings} id='scroll' className={`scroll slider-scroll`}>
            <div className="horizontal col-12">
                    <div className="body">                                              
                        <ul className="texts">
                            <h1 className="title">{uzbek && "Afzalliklar"} {russian && "Преимущества"}</h1>
                            <li className="name"><Verified className='icon' /> {uzbek &&"Avtomatlashtirilgan mikroiqlim tizimi va har bir xona uchun yoritish stsenariylarini boshqarish bilan jihozlangan Aqlli uyingizni energiya xarajatlarini sezilarli darajada tejaydi."} {russian && "Оснащенный автоматизированной системой микроклимата и управлением сценариями освещения для каждой комнаты, ваш Умный дом значительно сэкономит затраты на электроэнергию."}</li>
                            <li className="name"><ControlPoint className='icon' />{uzbek && "Istalgan vaqtda smartfoningizda videokuzatuv kameralarini ko'rsatishingiz va uyingizning holatini tekshirishingiz mumkin."} {russian && "Вы можете показать камеры видеонаблюдения и проверить состояние вашего дома в любое время на вашем смартфоне."}</li>
                            <li className="name"><Sell className='icon' /> {uzbek && " Favqulodda vaziyatlar haqida masofaviy xabarnomalarni ham uy ichida, ham mahalliy joylarda avtomatlashtirilgan usulda aniqlay olasiz"}{russian && "Вы можете автоматически обнаруживать удаленные аварийные оповещения как в помещении, так и локально"}</li>
                        </ul>
                    </div>
                </div>
                <div className="horizontal col-12">
                    <div className="body">                                              
                    <ul className="texts">
                            <h1 className="title">{uzbek && "Afzalliklar"} {russian && "Преимущества"}</h1>
                            <li className="name"><Verified className='icon' /> {uzbek &&"Tizim sizni gaz portlashlari va yong'inlardan himoya qiladi"} {russian && "Система защитит вас от взрыва газа и возгорания"}</li>
                            <li className="name"><ControlPoint className='icon' />{uzbek && "Endi uy kinoteatringizni boshqarish oson va sodda - bir tugmani bosish bilan siz kerakli stsenariyni ishga tushirasiz ."} {russian && "Теперь управлять домашним кинотеатром легко и просто - нажатием кнопки вы запустите нужный сценарий."}</li>
                            <li className="name"><Sell className='icon' /> {uzbek && " Suv, gazning oqishi, konditsionerning noto'g'ri ishlashi - avtomatlashtirish tizimi sizni hamma narsa haqida ogohlantiradi"}{russian && "Вода, утечка газа, неисправность кондиционера - система автоматики обо всем вас предупредит"}</li>
                        </ul>
                    </div>
                </div>
                <div className="horizontal col-12">
                    <div className="body">                        
                    <ul className="texts">
                            <h1 className="title">{uzbek && "Afzalliklar"} {russian && "Преимущества"}</h1>
                            <li className="name"><Verified className='icon' /> {uzbek &&"Faqat bitta tugmani bosish bilan yorug'lik xiralashadi, pardalar yopiladi, mikroiqlim muvozanatlanadi. "} {russian && "Нажатием всего одной кнопки приглушается свет, закрываются шторы и балансируется микроклимат."}</li>
                            <li className="name"><ControlPoint className='icon' />{uzbek && "Yorqin xonalar, yorug'lik oqimlari, interyerni ijobiy ta'kidlaydi - do'stlaringiz uyingizda qulay va erkin his qiladilar."} {russian && "Светлые комнаты, потоки света, выгодно подчеркивающие интерьер - Ваши друзья будут чувствовать себя в Вашем доме комфортно и уютно."}</li>
                            <li className="name"><Sell className='icon' /> {uzbek && "Siz uxlayotganingizda, yorug'likni tekshirib, xonalarni kezishingiz shart emas. Choyshab yonidagi kalitni bir marta bosish bilan siz uydagi barcha chiroqlarni o'chirasiz va yoqimli uyquga ketasiz."}{russian && "Вам не нужно бродить по комнате, проверяя свет во время сна. Одним щелчком выключателя рядом с кроватью вы выключите весь свет в доме и отправитесь в приятный сон."}</li>
                        </ul>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default Advantage;